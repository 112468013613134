import React from "react";

function NotFound() {
  return (
    <div style={{ textAlign: "center" }} className="container">
      <br />
      <br />
      <br />
      <br />
      <h1>Oops! Page Not Found!</h1>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default NotFound;
